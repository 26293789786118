import React from "react";

const Social = (props) => {
  return (
    <div className={`${props.addedClass || ""}`}>
      <a href={props.url} target="_blank" rel="noreferrer">
        {props.socialTitle}
      </a>
    </div>
  );
};

export default Social;
