import React, { useState, useEffect } from "react";
import TwoColumnsLayout from "../TwoColumnsLayout/TwoColumnsLayout";
import SocialMedia from "../SocialMedia/SocialMedia";
import InfoAboutus from "./AboutUs-info";
import ContactUsComponent from "../ContactUsComponent/ContactUsComponent";
import "./aboutUsBanner.scss";
const AboutUsBanner = (props) => {
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 400 && window.scrollY <= 3300) {
          setFixed(true);
        } else {
          setFixed(false);
        }
      });
    }
  }, []);
  const TwoColumnsLayoutContent = {
    leftContent: (
      <div className="leftContent">
        <div className="imageConatiner">
          <img
            className="desktopImage"
            src={`https:${props.aboutUsBannerData.aboutUsImages[0].file.url}`}
            alt="about us"
            loading="lazy"
          />
          <img
            className="desktopMobile"
            src={`https:${props.aboutUsBannerData.aboutUsMobileBanner.file.url}`}
            alt="about us"
            loading="lazy"
          />
        </div>
      </div>
    ),
    rightContent: (
      <>
        <div className="rightContent">
          <div className="info-container">
            <h1> {props.aboutUsBannerData.aboutUsBannerTitle}</h1>
            <h4> {props.aboutUsBannerData.aboutTextRed}</h4>
            <p> {props.aboutUsBannerData.description1.description1}</p>
            <br />
            <p> {props.aboutUsBannerData.description2.description2}</p>
          </div>
          <div className="social"></div>
        </div>
      </>
    ),
  };
  return (
    <div className=" aboutUS-banner ">
      <div
        className={fixed ? "social-buttons fixed " : "social-buttons"}
        style={{ top: !fixed ? "300px" : "" }}
      >
        <SocialMedia socialMediaData={props.socialMediaAbout} />
      </div>
      <div>
        <TwoColumnsLayout
          addedClass="aboutLayout"
          leftContent={TwoColumnsLayoutContent.leftContent}
          rightContent={TwoColumnsLayoutContent.rightContent}
        />
      </div>

      <InfoAboutus aboutUsBannerData={props.aboutUsBannerData} />
      <ContactUsComponent contactUsData={props.contactUsAbout} />
    </div>
  );
};

export default AboutUsBanner;
