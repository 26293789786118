import React, { useState, useEffect } from "react";
import Social from "./Social";
import "./socialMedia.scss"

const SocialMedia = (props) => {
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 400) {
          setFixed(true);
        } else {
          setFixed(false);
        }
      });
    }
  }, []);
  return (
    <div className="btn-social">
      {props.socialMediaData.map((el, ind) => {
        return (
          <Social
            key={ind}
            socialTitle={el.socialMediaTitle}
            addedClass={`btn-social-${el.socialMediaTitle}`}
            url={el.socialMediaLink}
          />
        );
      })}
    </div>
  );
};

export default SocialMedia;
