import React from "react";
import "./AboutUsinfo.scss";
import "../../styles/core/_button.scss";

const InfoAboutus = (props) => {
  return (
    <>
      <div className="container-aboutUs">
        <div className="secondtext">
          <h3> {props.aboutUsBannerData.secondTitle[0]}</h3>
          <strong>{props.aboutUsBannerData.secondTitle[1]}</strong>
        </div>

        <div className="container-aboutUs-banner">
          <div>
            <img
              alt="logo banner"
              src={`https:${props.aboutUsBannerData.logoBanner1.file.url}`}
              loading="lazy"
            />
            <p>{props.aboutUsBannerData.shortTextBaner[0]}</p>
          </div>

          <div>
            <img
              alt="logo banner"
              src={`https:${props.aboutUsBannerData.logoBanner2.file.url}`}
              loading="lazy"
            />
            <p>{props.aboutUsBannerData.shortTextBaner[1]}</p>
          </div>

          <div>
            <img
              alt="logo banner"
              src={`https:${props.aboutUsBannerData.logoBanner3.file.url}`}
              loading="lazy"
            />
            <p>{props.aboutUsBannerData.shortTextBaner[2]}</p>
          </div>
        </div>

        <br />
        <div className="about-us-learn-more-info">
          <div>
            <h3> {props.aboutUsBannerData.titleBanner3[0]}</h3>
            <p>{props.aboutUsBannerData.description3.description3}&nbsp;</p>
            <br />
            <p>
              {props.aboutUsBannerData.description4.description4}
              <br />
              <br />
              {props.aboutUsBannerData.description5}
            </p>
          </div>
          <div>
            <h3> {props.aboutUsBannerData.titleBanner3[1]}</h3>

            <p>{props.aboutUsBannerData.description6.description6}&nbsp;</p>
            <br />
            <p>{props.aboutUsBannerData.description7.description7}&nbsp;</p>
          </div>
          <div>
            <h3> {props.aboutUsBannerData.titleBanner3[2]}</h3>
            <p>{props.aboutUsBannerData.description8.description8}&nbsp;</p>
            <br />
            <p>{props.aboutUsBannerData.description9.description9}</p>
          </div>
        </div>
        
      </div>
      <div>
        <h2 className="ourprocess-about-title">
          {props.aboutUsBannerData.processTitle}
        </h2>
      </div>
      <div className="grid-container">
        <div className="item1">
          <h6>{props.aboutUsBannerData.processSecondTitle[0]}</h6>
          <p>
            {props.aboutUsBannerData.processDescription3.processDescription3}
          </p>
        </div>
        <div className="item2">
          <h4 className="redText">{props.aboutUsBannerData.processRedText}</h4>

          <p>
            {props.aboutUsBannerData.processDescription1.processDescription1}
            <br />
            <br />
            {props.aboutUsBannerData.processDescription2.processDescription2}
          </p>
        </div>
        <div className="item3">
          <h6>{props.aboutUsBannerData.processSecondTitle[2]}</h6>
          <p>
            {props.aboutUsBannerData.processDescription5.processDescription5}
          </p>
        </div>
        <div className="item4">
          <h6> {props.aboutUsBannerData.processSecondTitle[1]}</h6>
          <p>
            {props.aboutUsBannerData.processDescription4.processDescription4}
          </p>
        </div>
        <div className="item5">
          <h6>{props.aboutUsBannerData.processSecondTitle[3]}</h6>
          <p>
            {props.aboutUsBannerData.processDescription6.processDescription6}
          </p>
        </div>
        <div className="item6">
          <img className="desktop" src={`https:${props.aboutUsBannerData.processImage.file.url}`} alt="Product Image" loading="lazy" />
          <img className="mobile" src={`https:${props.aboutUsBannerData.processImage.file.url}`} alt="Product Image" loading="lazy" />
        </div>
        <div className="item7">
          <h6>{props.aboutUsBannerData.processSecondTitle[4]}</h6>
          <p>
            {props.aboutUsBannerData.processDescription7.processDescription7}
          </p>
        </div>
        <div className="item8">
          <h6> {props.aboutUsBannerData.processSecondTitle[5]}</h6>
          <p>
            {props.aboutUsBannerData.processDescription8.processDescription8}
          </p>
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default InfoAboutus;
